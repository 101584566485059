.hero{
    display: block;
}
.all{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-left: 40px;
    margin-right: 40px;
}
.MainLeft{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
.title h1{
    font-size: 5rem;
    text-align: left;
    color: white;
    font-weight: bolder;
    text-transform: uppercase;
    margin-bottom: 0;
}
.title h1 span{
    background: -webkit-linear-gradient(67deg,#4b74e6 10%, #8c55e9 80%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.title p{
    color: gray;
    max-width: 650px;
}
.emailbar{
    border: 1px solid white;
    width: max-content;
    padding: 5px;
    border-radius: 7px;
}
.emailbar input{
    background: transparent;
    border: none;
    font-size: 16px;
}
.fugures{
    display: flex;
    gap: 2rem;
    
    margin-top: 15px;
}
.fugures>div{
display: flex;
flex-direction: column;
}
.fugures>div>span:nth-of-type(1){
    color: white;
    font-size: 2rem;
}
.fugures>div>span:nth-of-type(2){
    color: gray;
    text-transform: none;
}
.mainright{
    display: flex;
    align-items: center;
}
.hero-blur{
    width: 22rem;
    height: 20rem;
    left: 0;
    bottom: 0;
}
.hero-blur2{
    width: 22rem;
    height: 20rem;
    right: 0;
    top: 100;
}
.Image{
    width: 40rem;
    height: 38rem;
}