.footer{
    background-color: #1F1F2A;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}
.Test{
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
    margin-top: 40px;
}
.Test h1{
    color: white;
    font-size: 18px;
    font-weight: 500;
    margin-top: 15px;
}
.social{
    display: flex;
    flex-direction: row;
    gap: 30px;
    margin-top: 13px;

}
.social img {
    width: 2rem;


}
.Test ul{
    display: flex;
    list-style: none;
    gap: 15px;
    flex-direction: column;
    padding: 0;
}
.Test ul li{
    color: white;
    font-size: 18px;
    font-weight: 500;
}
.first{
    margin-top: 25px;
    display: flex;
    flex-direction: column;
    gap: 9px;
}
.first p{
    color: white;
    width: 270px;
    font-size: 18px;
    margin: 0;
}