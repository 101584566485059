.plans{
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    margin-left: 40px;
    margin-right: 40px;
}
.maiin{
    display: flex;
    flex-direction: row;
    gap: 45px;
    justify-content: center;
}
.plan{
    display: flex;
    flex-direction: column;
    border-radius: 45px 45px 0px 0px;
    background: linear-gradient(#12121D, #12121D) padding-box,    
    linear-gradient(to left, #4b74e6, #8c55e9) border-box;
  border: 3px solid transparent;
    color: white;
    gap:2rem;
    padding: 1.5rem;
    width: 15rem;
}
.plan:nth-child(2){
transform: scale(1.1);
background: linear-gradient(60deg, #8c55e9 40%, #4b74e6 80%);cursor: pointer;

}
.plan:nth-child(2)>svg{
    fill: white;
    width: 2rem;
    height: 2rem;
    }
.plan>svg{
fill: #8c55e9;
width: 2rem;
height: 2rem;
}
.plan>:nth-child(2){
    font-size: 1rem;
    font-weight: bold;
}
.plan>:nth-child(3){
    font-size: 3rem;
    font-weight: bold;
}

.features{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.feature{
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
}
.feature>img{
    width: 1rem;
}
.plan>:nth-child(2)>svg{
    fill: white;
}
.plan:nth-child(2)>button{
    color: #8c55e9;
    background: white;
    font-weight:700;
}
.btn1{
    padding-top: 10px;
    padding-left: 25px;
    border-radius: 6px;
    border: none;
    color: WHITE;
    background: linear-gradient(67deg,#4b74e6 10%, #8c55e9 80%);
    padding-bottom: 10px;
    padding-right: 25px;
    font-size: 18px;
    font-weight:700;
}

.Planheader ul{
    list-style:none;
    margin: 0;
  }
  .Planheader  ul li{
    display:inline-block;
    padding:5px 10px;
    text-align:center;
    font-size:24px;
    font-family:arial;
    position:relative;
    margin:10px
  }
  .Planheader  ul li a{
    text-decoration:none;
    background: -webkit-linear-gradient(60deg, #8c55e9 40%, #4b74e6 80%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight:700;
    text-transform:uppercase;
  }
  .Planheader  ul li:before{
    position:absolute;
    content:'';
    width:50px;
    height:20px;
    opacity:1;
    left:-10px;
    top:-10px;
    border-top:5px solid #8c55e9;
    border-left:5px solid #8c55e9;
    transition:0.5s;
  }
  .Planheader ul li:after{
    position:absolute;
    content:'';
    width:50px;
    height:20px;
    border-bottom:5px solid  #4b74e6;
    border-right:5px solid #4b74e6;
    transition:0.5s;
    opacity:1;
    right:-10px;
    bottom:-10px;
  }
  .Planheader p{
    font-size: 2rem;
    text-align: left;
    color: white;
    text-transform: uppercase;
    margin-bottom: 0;
    font-weight: bold;
    margin-left: 35px;
    margin-bottom: 35px;
}
.Planheader p span{
    background: -webkit-linear-gradient(67deg,#4b74e6 10%, #8c55e9 80%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.hero-blur3{
    width: 22rem;
    height: 20rem;
    left: 0;
    top: 16;
}
