.programs{
    display: flex;
    flex-direction: column;
    margin-left: 40px;
    margin-right: 40px;
}
.programs-categories{
    display: flex;
    gap: 1rem;
    justify-content: center;
    align-items: center;
}
.category{
    display: flex;
    flex-direction: column;
    padding: 2rem;
    gap: 1rem;
    color: white;
    justify-content: space-between;
    margin-bottom: 25px;
   width: 260px;
    border-radius: 65px 65px 0px 0px;
    background: linear-gradient(#12121D, #12121D) padding-box,    
    linear-gradient(to left, #4b74e6, #8c55e9) border-box;
  border: 3px solid transparent;
}
.category>:nth-child(1){
    width: 2rem;
    height: 2rem;fill:white;
}
.category>:nth-child(2){
    font-size: 1rem;
    font-weight: bold;
}
.category>:nth-child(3){
    font-size: 0.9rem;
    line-height: 25px;

}
.arrowright{
    display: flex;
    justify-content: flex-end;
    
}
.category:hover{
    background: linear-gradient(60deg, #8c55e9 40%, #4b74e6 80%);cursor: pointer;
    
}
.programsheader{
    display: flex;
    flex-direction: column;
}

  
.programsheader ul{
    list-style:none;
  }
  .programsheader  ul li{
    display:inline-block;
    padding:5px 10px;
    text-align:center;
    font-size:24px;
    font-family:arial;
    position:relative;
    margin:10px
  }
  .programsheader  ul li a{
    text-decoration:none;
    background: -webkit-linear-gradient(60deg, #8c55e9 40%, #4b74e6 80%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight:700;
    text-transform:uppercase;
  }
  .programsheader  ul li:before{
    position:absolute;
    content:'';
    width:50px;
    height:20px;
    opacity:1;
    left:-10px;
    top:-10px;
    border-top:5px solid #8c55e9;
    border-left:5px solid #8c55e9;
    transition:0.5s;
  }
  .programsheader ul li:after{
    position:absolute;
    content:'';
    width:50px;
    height:20px;
    border-bottom:5px solid  #4b74e6;
    border-right:5px solid #4b74e6;
    transition:0.5s;
    opacity:1;
    right:-10px;
    bottom:-10px;
  }
  .programsheader p{
    font-size: 2rem;
    text-align: left;
    color: white;
    text-transform: uppercase;
    margin-bottom: 0;
    font-weight: bold;
    margin-left: 35px;
    margin-top: 25px;
    margin-bottom: 35px;
}
.programsheader p span{
    background: -webkit-linear-gradient(67deg,#4b74e6 10%, #8c55e9 80%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}