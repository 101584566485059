.service{
    display: flex;
    margin-left: 40px;
    margin-right: 40px;
    
    flex-direction: column;
}
.serviceheader{
    display: flex;
    gap: 1rem;
    flex-direction: column;
}
.serviceheader ul{
    list-style:none;
    margin: 0;
  }
  .serviceheader  ul li{
    display:inline-block;
    padding:5px 10px;
    text-align:center;
    font-size:24px;
    font-family:arial;
    position:relative;
    margin:10px
  }
  .serviceheader  ul li a{
    text-decoration:none;
    background: -webkit-linear-gradient(60deg, #8c55e9 40%, #4b74e6 80%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight:700;
    text-transform:uppercase;
  }
  .serviceheader  ul li:before{
    position:absolute;
    content:'';
    width:50px;
    height:20px;
    opacity:1;
    left:-10px;
    top:-10px;
    border-top:5px solid #8c55e9;
    border-left:5px solid #8c55e9;
    transition:0.5s;
  }
  .serviceheader ul li:after{
    position:absolute;
    content:'';
    width:50px;
    height:20px;
    border-bottom:5px solid  #4b74e6;
    border-right:5px solid #4b74e6;
    transition:0.5s;
    opacity:1;
    right:-10px;
    bottom:-10px;
  }
  .serviceheader h1{
    font-size: 2rem;
    text-align: left;
    color: white;
    text-transform: uppercase;
    margin-bottom: 0;
    font-weight: bold;
    margin-left: 35px;
    margin-top: 0;
}
.serviceheader h1 span{
    background: -webkit-linear-gradient(67deg,#4b74e6 10%, #8c55e9 80%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.serviceheader p {
    color: gray;
    max-width: 650px;
    font-size: 18px;
    font-weight: bold;
    margin-left: 35px;
    margin-top: 0;
    margin-bottom: 0;
}

.Serv{
    display: flex;
    flex-direction: row;
    font-size: 12px;
    margin-left: 40px;
    margin-top: 20px;
    gap: 20px;
}
.image{
  display: flex;
  flex-direction: column;
  gap: 20px;}
.task{
  display: flex;
  flex-direction: row;
  gap: 15px;
  color: gray;
  align-items: center;
  font-size: 20px;
}
.ri-check-double-fill{

  font-size: 40px;
 background: -webkit-linear-gradient(67deg,#4b74e6 10%, #8c55e9 80%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.btnn{
  padding-top: 10px;
  padding-left: 25px;
  border-radius: 6px;
  border: none;
  color: WHITE;
  background: linear-gradient(67deg,#4b74e6 10%, #8c55e9 80%);
  padding-bottom: 10px;
  padding-right: 25px;
  font-size: 15px;
  width: max-content;
}
.imaaa{
  margin-top: -240px;
}