/*Font */
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;1,400;1,600&display=swap');

.constraint{
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-family: 'Source Sans Pro', sans-serif;
    gap: 400px;
}
.left{
    display: flex;
    align-items: center;
    justify-content:flex-start;
    gap: 20px;
}
.right{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
}
.Logo {
    width: 7rem;
    height: 6rem;
}
.container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}
/*.container a{
    position: relative;
    width: 100px;
    height: 40px;
    display: inline-block;
    background-color: #fff;
    margin: 10px;
}
.container a:before, .container a:after{
    content: '';
    position: absolute;
    inset: 0;
    background: linear-gradient(45deg,#4b74e6,#0e1538,#0e1538,#8c55e9);
    transition: 0.2s;
}
.container a:hover:before{
    inset: -3px;
}
.container a:hover:after{
    inset: -3px;
    filter: blur(10px);
}
.container a span{
    position: absolute;
    top: 0;
    left: 0;
    width: 100px;
    height: 40px;
    display: inline-block;
    background: #12121D;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    letter-spacing: 2px;
    color: #fff;
}*/
.container ul{
    list-style:none;
  }
  .container  ul li{
    display:inline-block;
    padding:5px 10px;
    text-align:center;
    font-size:19px;
    font-family:arial;
    position:relative;
    margin:10px
  }
  .container  ul li a{
    text-decoration:none;
    color:White;
    font-weight:700;
    text-transform:uppercase;
  }
  .container ul li:before{
    position:absolute;
    content:'';
    width:50px;
    height:20px;
    left:0;
    top:0;
    border-top:5px solid #8c55e9;
    border-left:5px solid #8c55e9;
    opacity:0;
    transition:0.5s;
  }
  .container ul li:hover:before{
    opacity:1;
    left:-10px;
    top:-10px;
  }
  
  .container  ul li:after{
    position:absolute;
    content:'';
    width:50px;
    height:20px;
    right:0;
    bottom:0;
    border-bottom:5px solid #4b74e6;
    border-right:5px solid #4b74e6;
    opacity:0;
    transition:0.5s;
  }
  .container  ul li:hover:after{
    opacity:1;
    right:-10px;
    bottom:-10px;
  }
.icons{
    display: flex;
    align-items: center;
    gap: 12px;
    color: white;
    font-size: 18px;
}
.icons span{
    background: -webkit-linear-gradient(67deg,#4b74e6 10%, #8c55e9 80%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.ri-arrow-down-s-line{
    font-size: 22px;
    background: -webkit-linear-gradient(67deg,#4b74e6 10%, #8c55e9 80%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.btn{
    padding-top: 10px;
    padding-left: 25px;
    border-radius: 6px;
    border: none;
    color: WHITE;
    background: linear-gradient(67deg,#4b74e6 10%, #8c55e9 80%);
    padding-bottom: 10px;
    padding-right: 25px;
    font-size: 15px;
}
/*.container ul{
    display: contents;
    list-style:none;
    color: white;
  }
  .container  ul li{
    display:inline-block;
    padding:5px 10px;
    text-align:center;
    font-size:18px;
    font-family:arial;
    margin:10px
  }
  .container   ul li a{
    text-decoration:none;
    color:white;
    font-weight:700;
    text-transform:uppercase;
  }
  .container   ul li:before{
    content:'';
    width:50px;
    height:20px;
    left:0;
    top:0;
    border-top:5px solid #8c55e9;
    border-left:5px solid #8c55e9;
    opacity:0;
    transition:0.5s;
  }
  .container   ul li:hover:before{
    opacity:1;
    left:-10px;
    top:-10px;
  }
  
  .container   ul li:after{
    content:'';
    width:50px;
    height:20px;
    right:0;
    bottom:0;
    border-bottom:5px solid #4b74e6;
    border-right:5px solid #4b74e6;
    opacity:0;
    transition:0.5s;
  }
  .container   ul li:hover:after{
    opacity:1;
    right:-10px;
    bottom:-10px;
  }*/