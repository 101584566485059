/* make variables */
:root {
  --lightgray: #D9D9D9;
  --gray: #9c9c9c;
  --orange: #f48915;
  --darkGrey: #464D53;
  --caloryCard: #656565;
  --planCard: linear-gradient(210.41deg, #fa5042 1.14%, #ffa739 100.75%);
  --appColor:  #3c3f45;
}

.App {
  background: #12121D;
  mix-blend-mode: overlay;
  display: flex;
  flex-direction: column;
  gap: 6rem;
  overflow: hidden;
}
::-webkit-scrollbar{
  display: none;
}
.blur{
background: #4b74e6;
position: absolute;
border-radius: 50%;
filter: blur(200px);
z-index: -9;
}